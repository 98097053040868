<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row align-content="center" style="height: 100vh">
          <v-col cols="12">
            <v-row justify="center">
              <v-card elevation="3" max-width="450px" width="100%">
                <v-img
                  :src="require('@/assets/images/logos/devias.png')"
                  max-height="500px"
                  max-width="250px"
                  alt="logo"
                  contain
                  class="mx-auto"
                ></v-img>
                <v-card-title primary-title>
                  Recuperar contraseña
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    type="email"
                    placeholder="correo@ejemplo.com"
                    label="Correo"
                    prefix="@"
                    outlined
                    color="primary"
                    dense
                    v-model="email"
                    v-on:keyup.enter="recuperar"
                  ></v-text-field>

                  <v-alert type="error" elevation="1" color="error" class="mt-2" v-if="errores.length > 0">
                    <span v-for="(error, index) in errores" v-bind:key="index" class="d-block">
                      {{ error }}
                    </span>
                  </v-alert>
                  <v-alert type="info" elevation="1" color="info" v-if="message != ''">
                    <p>{{ message }}</p>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="mb-4 mx-4">
                  <v-row dense justify="end">
                    <v-col cols="auto">
                      <v-btn color="primary" @click="recuperar" :loading="disableButton">Recuperar</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'recuperar-contrasena',
  data() {
    return {
      email: '',
      errores: [],
      message: '',
      disableButton: false,
    }
  },
  methods: {
    recuperar() {
      this.disableButton = true
      this.message = ''
      this.errores = []
      let v = this

      let data = {
        email: v.email,
      }

      this.$http
        .get(this.$base_api_sanctum_url + '/sanctum/csrf-cookie')
        .then(() => {
          this.$http
            .post(v.$base_api_url + '/recuperar-contrasena', data)
            .then(() => {
              v.message = 'Se envio un email de recuperacion de contraseña al correo indicado'
              v.email = '';
            })
            .catch(res => {

              if (res.response == undefined) {
                v.errores.push('Error inesperado, intenta mas tarde')
                return null
              }
              if (res.response.status == 422) {
                v.errores = []
                let keys = Object.keys(res.response.data.errors)
                for (let i = 0; i < keys.length; i++) {
                  const element = res.response.data.errors[keys[i]]
                  element.forEach(e => {
                    v.errores.push(e)
                  })
                }
                return null
              }
              if (res.response.status == 429) {
                v.errores.push('Demaciados intentos, espera un minuto')
                return null
              }

              v.errores.push(`Error: ${res.response.status}, ${res.response.statusText}`)
            })
            .then(() => {
              this.disableButton = false
            })
        })
        .catch(e => {
          this.catchResponse(e)
          this.loadingForm = false
        })
    },
  },
}
</script>

<style lang="css"></style>
